import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiAddCircleFill, RiDeleteBin2Fill, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();

    this.newCal = {
      percent: '',
      max: '',
      result: ''
    };
    
    this.state = {
      calc: [JSON.parse(JSON.stringify(this.newCal))]
    }

    this.copy = 2024;
    this.today = new Date();
    
    this.currChange = this.currChange.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){}

  currChange(value, event){
    if(typeof event.event !== 'undefined'){
      const indexInput = event.event.target.name.split('-');

      let listCalc = [...this.state.calc];

      listCalc[indexInput[1]][indexInput[0]] = value.floatValue;
      
      if(listCalc[indexInput[1]].percent && listCalc[indexInput[1]].max){
        listCalc[indexInput[1]].result = parseFloat((listCalc[indexInput[1]].max / (listCalc[indexInput[1]].percent / 100)).toFixed(2));
      }else{
        listCalc[indexInput[1]].result = '';
      }

      this.setState({ calc: listCalc });
    }
  }

  add(){
    let listCalc = [...this.state.calc];

    listCalc.push(JSON.parse(JSON.stringify(this.newCal)));

    this.setState({ calc: listCalc });
  }

  remove(event){
    let listCalc = [...this.state.calc];

    listCalc.splice(event.target.dataset.index, 1);

    this.setState({ calc: listCalc });
  }

  render(){
    return (
      <>
        <Helmet>
          <title>MaxPromo {`${this.state.calc[0].result ? this.state.calc[0].percent + '% from max. ' + this.state.calc[0].max + ' is ' + this.state.calc[0].result : ''}`}</title>
        </Helmet>

        <header>
          <a href="/">
            <Logo />
          </a>
        </header>

        <div className="form">
          <ul>
            {this.state.calc.map((value, index) => (
              <li key={index}>
                <div className="calc">
                  <button type="button" onClick={this.remove} data-index={index} disabled={this.state.calc.length === 1}><RiDeleteBin2Fill /></button>
                  <ul>
                    <li>
                      <label>Discount Promo Percentage</label>
                      <NumericFormat
                        name={`percent-${index}`}
                        placeholder="Eg: 20"
                        thousandSeparator="."
                        decimalSeparator=","
                        value={value.percent}
                        allowNegative={false}
                        onValueChange={this.currChange}
                        isAllowed={(values) => {
                          if(values.value){
                            if(values.floatValue <= 100){
                              return true;
                            }else{
                              toast.error(() => (<>Maximum Percentage Exceed<span>Maximum number of percentage is <strong>100</strong></span></>));
                            }
                          }else{
                            return true;
                          }
                        }}
                        // isAllowed={(values) => {
                        //   return values.floatValue > 0 && values.floatValue <= 100;
                        // }}
                        pattern="[0-9]*"
                      />
                      <span className="unit">%</span>
                    </li>
                    <li>
                      <label>Maximum Discount Amount</label>
                      <NumericFormat
                        name={`max-${index}`}
                        placeholder="Eg: 25.000"
                        thousandSeparator="."
                        decimalSeparator=","
                        className="no-unit"
                        value={value.max}
                        allowNegative={false}
                        onValueChange={this.currChange}
                        // isAllowed={(values) => {
                        //   return values.floatValue > 0;
                        // }}
                        pattern="[0-9]*"
                      />
                    </li>
                  </ul>
                </div>
                {value.result ? (
                  <div className="result">
                    <div className="label">Minimum amount to spent to get maximum discount promo</div>
                    <NumericFormat displayType="text" thousandSeparator="." decimalSeparator="," value={value.result} />
                  </div>
                ) : ''}
              </li>
            ))}
            <li>
              <button type="button" onClick={this.add}>Add More<RiAddCircleFill /></button>
            </li>
          </ul>
        </div>
        
        <footer>
          <a href="/">MaxPromo</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
          <div>Inspired from <a href="https://github.com/harisol/spending_calc" target="_blank" rel="noreferrer">spending_calc</a> by <a href="https://github.com/harisol" target="_blank" rel="noreferrer">Harisol</a>.</div>
        </footer>
      </>
    );
  }
}

export default App;